import {HeroSection, PlayHeroSection} from "../components";
import DigitalAwardSection from "../components/DigitalAwardSection/DigitalAwardSection";
import DiscoverPrizeSection from "../components/DiscoverPrizeSection/DiscoverPrizeSection";
import Subscribe from "../components/Subscribe/Subscribe";
import Summary from "../components/Summary/Summary";
import ClaimPrize from "../components/ClaimPrize/ClaimPrize";
import ModalSubscribe from "../components/ModalSubscribe/ModalSubscribe";
import useModalUtils from "../utils/useModalUtils";
import "../assets/scss/page/Home.scss"
import {useUser} from "../utils/useUserExist";
import {useAuthContext} from "../utils/AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Modal from "../components/Modal/Modal";
import CannotParticipate from "../components/CannotParticipate/CannotParticipate";

export const Home = () => {
    const navigate = useNavigate();
    const {openModal, closeModal, isModalOpen, ModalType} = useModalUtils();
    const [isModalAlreadyOpened, setIsModalAlreadyOpened] = useState(false);

    const user = useUser();
    const {isAuth} = useAuthContext();
    const {responseError} = useAuthContext();

    const [searchParams] = useSearchParams();

    const openModalParam = searchParams.get("openModal");
    const isOpenModal = openModalParam === "true";


    useEffect(() => {

        if (!isOpenModal || isModalAlreadyOpened) return;
        if (isOpenModal && responseError === 403) {
            openModal(ModalType.CANNOT_PARTICIPATE)
            setIsModalAlreadyOpened(true);
        }
        if (isOpenModal && responseError !== 403) {
            openModal(ModalType.SUBSCRIBE)
            setIsModalAlreadyOpened(true);
        }

    }, [ModalType, openModal, responseError, isOpenModal, isModalAlreadyOpened]);

    const onModalClose = () => {
        navigate("/")
        closeModal(ModalType.CLOSE)
    }

    return (
        <div className="pw-container-home-page">
            {isAuth || user ? (
                <>
                    <PlayHeroSection/>
                    <iframe className="prize-iframe-dashboard"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMCBsYEaQBd0tcEEg/postepaypromopayandwinpremialit%C3%A0"
                            style={{width: '100vw', border: 'none'}}></iframe>
                    <DigitalAwardSection/>
                    <ClaimPrize/>
                </>
            ) : (
                <>
                    <iframe id="subscribe-frame" className="hero-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMCBsZraQBd0tdoBWIA/postepaypromopayandwin"
                            style={{width: '100vw', border: 'none'}}></iframe>
                    <Summary/>
                    <iframe className="iscriviti-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMCBsYEAYkAXdLXaMIA/postepaypromopayandwinbanner"
                            style={{width: '100vw', border: 'none'}}></iframe>

                    <iframe className="prize-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMCBsYEaQBd0tcEEg/postepaypromopayandwinpremialit%C3%A0"
                            style={{width: '100vw', border: 'none'}}></iframe>

                    <DigitalAwardSection/>
                    <iframe className="iscriviti-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMCBsYEAYkAXdLXaMIA/postepaypromopayandwinbanner"
                            style={{width: '100vw', border: 'none'}}></iframe>

                </>
            )}

            <>
                <ModalSubscribe isOpen={isModalOpen(ModalType.SUBSCRIBE)} onClose={() => onModalClose()}/>
                <Modal
                    isOpen={isModalOpen(ModalType.CANNOT_PARTICIPATE)}
                    onClose={() => onModalClose()}
                    isCannotPartecipateContent
                >
                    <CannotParticipate onClose={() => onModalClose()}/>
                </Modal>
            </>
        </div>
    );
};
