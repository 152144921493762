import React from "react";
import "./ModalLimitReached.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import limit_reached_image from "../../assets/images/limit_reached.webp";
import Modal from "../Modal/Modal";
import { ModalActionProps } from "../../models/modalAction";

const ModalLimitReached: React.FC<ModalActionProps> = ({ isOpen, onClose }) => {
    const { limit_reached: { altImg, title, description, buttonLabel } } = locale;
    return (
        <div className="pw-modal-limit-reached">
            <Modal isOpen={isOpen} onClose={onClose}>
                <div className="pw-finished-plays-container">
                    <SimpleContent
                        image={{ alt: altImg, src: limit_reached_image }}
                        title={title}
                        description={() =>
                            <p className="pw-simple-content-description paragraph-semi-bold-small">
                                {description}
                            </p>
                        }
                        confirmationButton={{
                            theme: "blue",
                            label: buttonLabel,
                            onClick: onClose,
                            className: "pw-button-finished-plays"
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default ModalLimitReached